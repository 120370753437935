"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPersonalDataSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const personal_info_1 = require("./personal-info");
const address_1 = require("./address");
const ui_1 = require("client/ui");
const components_1 = require("client/components");
const i18n_1 = require("client/i18n");
exports.BookingPersonalDataSection = (0, react_1.memo)((0, forms_1.controlled)(({ control: { bind }, errors, ...props }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact.personalData;
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    return ((0, jsx_runtime_1.jsxs)(ui_1.Section, { title: t.header, ...props, children: [(0, jsx_runtime_1.jsx)(personal_info_1.BookingPersonalInfoForm, { ...bind.entire(), errors: errors }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$truncated": true, "$space": 10 }), (0, jsx_runtime_1.jsx)(address_1.BookingPersonalAddressForm, { ...bind.path('address'), ...validation.extract('address', { nested: true }) })] }));
}));
exports.BookingPersonalDataSection.displayName = 'BookingPersonalDataSection';
