"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferDescription = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const booking_offer_description_subtitle_1 = require("./booking-offer-description-subtitle");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const features_1 = require("client/modules/booking/booking-wizard/screens/03-room/shared/offer/features");
const md_description_1 = require("client/modules/md-description");
const i18n_1 = require("client/i18n");
const BookingOfferDescription = ({ offer, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const { room: { category }, offers, } = offer;
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(components_1.Text, { transform: "uppercase", color: "darkMuted", size: "sm", children: pack.common.roomCategory }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 1 }), (0, jsx_runtime_1.jsx)(ui_1.BaseModalHeader, { children: category.name }), (0, jsx_runtime_1.jsx)(booking_offer_description_subtitle_1.BookingOfferDescriptionSubtitle, { begin: new Date(offers[0].arrivalDay), nights: offers[0].nights }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6 }), (0, jsx_runtime_1.jsxs)(components_1.Text, { size: "sm", children: [category.teaser && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Paragraph, { children: category.teaser }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 2 })] })), (0, jsx_runtime_1.jsx)(md_description_1.MdDescriptionContent, { content: category.description })] }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6 }), (0, jsx_runtime_1.jsx)(features_1.BookingOfferFeatures, { features: category.features, truncateAfterNthItem: Infinity })] }));
};
exports.BookingOfferDescription = BookingOfferDescription;
