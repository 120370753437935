"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingCalendarRangeNightPrice = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const use_booking_smooth_selection_offers_range_1 = require("./use-booking-smooth-selection-offers-range");
const useBookingCalendarRangeNightPrice = ({ range, selectionOffers, roomOffer, }) => {
    const smoothSelectionOffersRange = (0, use_booking_smooth_selection_offers_range_1.useBookingSmoothSelectionOffersRange)(selectionOffers);
    return (0, react_1.useMemo)(() => {
        if (!range?.end || !range?.begin) {
            return null;
        }
        // prefer to calculate price based on room offer that is selected
        // in further steps but if it is not present ...
        if (roomOffer &&
            (0, commons_1.compareDateOnly)(new Date(roomOffer.arrivalDay))(range.begin) &&
            (0, commons_1.getTotalDatesRangeDates)(range) === roomOffer.nights) {
            return {
                ...roomOffer.price,
                amount: Math.ceil(roomOffer.price.amount / roomOffer.nights),
            };
        }
        // ... just display cheapest offers
        const offer = smoothSelectionOffersRange.find(item => !!item.date && (0, commons_1.compareDateOnly)(range.end)(item.date));
        if (!offer?.minimumPrice || !offer.nights) {
            return null;
        }
        return {
            ...offer.minimumPrice,
            amount: Math.ceil(offer.minimumPrice.amount / offer.nights),
        };
    }, [
        roomOffer,
        range?.begin?.toISOString(),
        range?.end?.toISOString(),
        smoothSelectionOffersRange,
    ]);
};
exports.useBookingCalendarRangeNightPrice = useBookingCalendarRangeNightPrice;
