"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkBookingRoomDayOfferV = void 0;
const zod_1 = require("zod");
const date_fns_1 = require("date-fns");
const dto_1 = require("../../../dto");
exports.SdkBookingRoomDayOfferV = zod_1.z
    .object({
    arrivalDay: zod_1.z.string().pipe(zod_1.z.coerce.date()),
    minimumPrice: dto_1.SdkPriceV.optional(),
    nights: zod_1.z.number().optional(),
})
    .transform(({ arrivalDay, ...attrs }) => ({
    ...attrs,
    arrivalDay,
    ...(attrs.nights && {
        date: (0, date_fns_1.addDays)(arrivalDay, attrs.nights),
    }),
}));
