"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPeopleInfoSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const room_persons_row_1 = require("./room-persons-row");
const i18n_1 = require("client/i18n");
const ui_1 = require("client/ui");
const components_1 = require("client/components");
exports.BookingPeopleInfoSection = (0, react_1.memo)((0, forms_1.controlled)(({ control: { value, bind }, errors }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact;
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    return ((0, jsx_runtime_1.jsx)(ui_1.Section, { title: t.peopleInfo.title, children: (0, jsx_runtime_1.jsx)(components_1.CleanList, { "$gap": 8, children: value.rooms.map((room, index) => ((0, jsx_runtime_1.jsx)(room_persons_row_1.BookingRoomPersonsRow, { index: index, ...bind.path(`rooms[${index}]`), ...validation.extract(`rooms[${index}]`, { nested: true }) }, room.meta.editorId))) }) }));
}));
exports.BookingPeopleInfoSection.displayName = 'BookingPeopleInfoSection';
