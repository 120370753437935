"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarTableWeekDaysRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Array_1 = require("fp-ts/Array");
const commons_1 = require("@gerber/commons");
const booking_calendar_table_week_days_row_styled_1 = require("./booking-calendar-table-week-days-row.styled");
const constants_1 = require("client/modules/booking/booking-calendar/constants");
const i18n_1 = require("client/i18n");
exports.BookingCalendarTableWeekDaysRow = (0, react_1.memo)(() => {
    const { lang } = (0, i18n_1.useI18n)();
    const translateDay = (day) => {
        const date = new Date(new Date('2023-05-01').setDate(day + 1));
        return (0, commons_1.getDayShortcut)(lang)(date).toUpperCase();
    };
    return ((0, jsx_runtime_1.jsx)(booking_calendar_table_week_days_row_styled_1.BookingWeekDaysHeaderRow, { children: (0, Array_1.makeBy)(constants_1.CALENDAR_WEEK_TOTAL_CELLS, day => ((0, jsx_runtime_1.jsx)(booking_calendar_table_week_days_row_styled_1.BookingWeekDaysHeaderColumn, { children: translateDay(day) }, day))) }));
});
exports.BookingCalendarTableWeekDaysRow.displayName = 'BookingCalendarTableWeekDaysRow';
