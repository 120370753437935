"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractShortDate = exports.extractUnsafeDate = exports.extractDate = void 0;
/**
 * ISO -> 2019-04-22
 */
const extractDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
};
exports.extractDate = extractDate;
const extractUnsafeDate = (date) => {
    if (!date) {
        return null;
    }
    return (0, exports.extractDate)(new Date(date));
};
exports.extractUnsafeDate = extractUnsafeDate;
/**
 * ISO -> 01.06.23
 */
const extractShortDate = (date, separator = '-') => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}${separator}${month}${separator}${year}`;
};
exports.extractShortDate = extractShortDate;
