"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRanges = void 0;
exports.DateRanges = {
    fromInfinity: (end) => ({
        begin: new Date('1000-01-01'),
        end,
    }),
    toInfinity: (begin) => ({
        begin,
        end: new Date('3000-01-01'),
    }),
};
