"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormatDate = void 0;
const commons_1 = require("@gerber/commons");
const i18n_context_1 = require("../i18n-context");
const useFormatDate = () => {
    const { lang } = (0, i18n_context_1.useI18n)();
    return {
        // 1 Januar 2023
        full: (year = true) => (date) => (0, commons_1.rejectFalsyItems)([
            date.getDate(),
            (0, commons_1.getMonthName)(lang)(date.getMonth()),
            year && date.getFullYear(),
        ]).join(' '),
        // 01.01.23
        shortcut: (dayName = true) => (date) => {
            const formattedDate = [
                date.getDate(),
                date.getMonth() + 1,
                date.getFullYear(),
            ]
                .map(number => number.toString().padStart(2, '0'))
                .join('.');
            if (dayName) {
                return `${(0, commons_1.getDayName)(lang)(date)} ${formattedDate}`;
            }
        },
    };
};
exports.useFormatDate = useFormatDate;
