"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkPageSalesInfoV = void 0;
const zod_1 = require("zod");
exports.SdkPageSalesInfoV = zod_1.z
    .object({
    from: zod_1.z.string().pipe(zod_1.z.coerce.date()),
    to: zod_1.z.string().pipe(zod_1.z.coerce.date()),
    text: zod_1.z.string().optional().nullable(),
    title: zod_1.z.string().optional().nullable(),
})
    .transform(({ from, to, ...attrs }) => {
    const value = {
        begin: from,
        end: to,
        ...attrs,
    };
    return value;
});
