"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUnsafeDate = void 0;
const is_invalid_date_1 = require("./is-invalid-date");
const parseUnsafeDate = (date) => {
    if (!date) {
        return null;
    }
    const parsed = new Date(date);
    if ((0, is_invalid_date_1.isInvalidDate)(parsed)) {
        return null;
    }
    return parsed;
};
exports.parseUnsafeDate = parseUnsafeDate;
